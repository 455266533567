






















































































































































import {Component, Vue} from 'vue-property-decorator'
import { blogFooterScopeOptions } from '~/components/data-class/data-class'
import {createRequest} from "~/utils/network-request";
import {BlogFooter} from "@afterschool.dev/as-data-admin";
import {ObjectID} from "@afterschool.dev/as-data-admin/lib/common/misc";
@Component({
    components: {},
    computed: {}
})
export default class BlogFooterList extends Vue {

    blogFooterScopeOptions = blogFooterScopeOptions

    // Filters
    searchTitle: string = ""
    selectedType: "" | "global" | "author" | "blog" = ""
    selectedInstructorMemberId = ""
    selectedBlogId = ""
    enabled: boolean | null = null

    currentPage: number = 1
    totalCount: number = 0
    step: number = 10

    tableData: Array<BlogFooter> = []
    // [
    //     {
    //         // _id: "123",
    //         enabled: true,
    //         // blog_id: null,
    //         blog_id: "BPID-00000112",
    //         member_id: null,
    //         content_json: {
    //             blocks: [
    //                 {
    //                     type: "paragraph",
    //                     data: {
    //                         text: "this is content"
    //                     }
    //                 }
    //             ]
    //         },
    //         start: new Date(),
    //         end: new Date()
    //     },
    // ]

    created() {
        const page = Number(this.$route.query['page'])
        if (!isNaN(page)) {
            this.currentPage = page
        }
        if (["", "global", "author", "blog"].includes(this.$route.query['type'] as string || '')) {
          this.selectedType = (this.$route.query['type'] || '') as ("" | "global" | "author" | "blog")
        }

        this.selectedInstructorMemberId = this.$route.query['member-id'] as string || ''
        this.selectedBlogId = this.$route.query['blog-id'] as string || ''
        // this.selectedStatus = Number(this.$route.query['status']) || -1
        // this.selectedTag = this.$route.query['tag'] as string || ''

        // this.updateBlogFooters(this.currentPage)
        this.updateBlogFooters()
    }

    updateBlogFooters(page: number = 1) {

        this.currentPage = page
        const q: any = {
            page: this.currentPage
        }
        const params: {
            enabled?: boolean,
            blog_id?: string,
            member_id?: string,
            type: "" | "global" | "author" | "blog",
            skip: number,
            limit: number,
            keyword: string
            // count: boolean
        } = {
            enabled: this.enabled,
            type: this.selectedType,
            skip: (this.currentPage - 1) * this.step,
            limit: this.step,
            keyword: this.searchTitle
            // count: true
        }

        if (this.selectedType === "author" && !!this.selectedInstructorMemberId) {
            params.member_id = this.selectedInstructorMemberId;
        }

        if (this.selectedType === "blog" && !!this.selectedBlogId) {
            params.blog_id = this.selectedBlogId;
        }

        // Object.entries(params).forEach((e) => {
        //     const key = e[0]
        //     const value = e[1]
        //     params[key] = value + ''
        // })

        createRequest('/blog/footer/list', 'get', params).send()
                .then((res) => {
                    this.tableData = res.data['blog_footers']
                    this.totalCount = res.data['count']
                }).catch((e) => {
                console.error(e)
            })
    }

    hisAbout(his) {
        if (!his.content_json.blocks) {
            return ''
        }
        return his.content_json.blocks.filter(b => b.type === 'paragraph')
            .reduce((str, b) => {
                return str + ' ' + b.data.text
            }, '')
    }

    async rowEnabledChanged(row: BlogFooter) {
        try {
            await createRequest(`/blog/footer/${row._id}`, 'patch', {}, {
                enabled: row.enabled
            }).send()
            this.$message.success('Updated')
        }
        catch (e) {
            this.$message.error('Update failed')
        }
    }

    remove(row: BlogFooter) {
        this.$confirm('Are you sure you want to delete this blog footer?', 'Warning', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning'
        }).then(async () => {
            try {
                await createRequest(`/blog/footer/${row._id}`, 'delete').send()
                this.$message.success('Deleted')
                await this.updateBlogFooters(this.currentPage)
            }
            catch (e) {
                this.$message.error('Deletion failed')
            }
        }).catch(() => {

        })
    }

}
